import React from 'react';

import { HashLink as Link } from 'react-router-hash-link';

import './Footer.css';

export const Footer = () => {
  return (
    <div className='footerSection'>
      <div className='footerMainSection'>
        <div className='aboutUsFooter footerDivWrapper'>
          <div className='header'>About Us</div>
          <div className='subtext'>
            Shivangan Hotel is a 2 star property located  minutes away from the Dum Dum Airport. Best  Hotel
            Near Kolkata Airport and Kolkata Railway  Station. The hotel features well-designed rooms  that
            are class.
          </div>
        </div>
        <div className='otherLinks footerDivWrapper'>
          <div className='header'>Other Links</div>
          <div className='linksGrid'>
            <Link to='/#Home' className='otherLink'>
              Home
            </Link>
            <Link to='/#About' className='otherLink'>
              About
            </Link>
            {/* <Link to='/#' className='otherLink'>
              Gallery
            </Link> */}
            <Link to='/contact' className='otherLink'>
              Contact
            </Link>
            <Link to='/#Clients' className='otherLink'>
              Client
            </Link>
            <Link to='/#Rooms' className='otherLink'>
              Rooms
            </Link>
            <Link to='/#Banquet' className='otherLink'>
              Banquet Hall
            </Link>
            <Link to='/#Conference' className='otherLink'>
              Conference Room
            </Link>
          </div>
        </div>
        <div className='contactInfo footerDivWrapper'>
          <div className='header'>Get in touch</div>
          <div className='contact'>
            <div className='logo'>
              <svg width='31' height='31' viewBox='0 0 31 31' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M30.741 23.3031C30.3841 22.923 29.1243 21.7992 26.8082 20.3124C24.4751 18.8125 22.7545 17.8774 22.261 17.6593C22.2175 17.64 22.1696 17.633 22.1223 17.6389C22.0751 17.6448 22.0304 17.6634 21.993 17.6928C21.198 18.3128 19.8595 19.4517 19.7877 19.5132C19.3243 19.9104 19.3243 19.9104 18.9449 19.7867C18.2777 19.5679 16.205 18.4673 14.399 16.6572C12.5929 14.847 11.4356 12.7231 11.2168 12.0566C11.0917 11.6765 11.0917 11.6765 11.4903 11.213C11.5518 11.1413 12.6914 9.80278 13.3114 9.00845C13.3408 8.97104 13.3594 8.92633 13.3653 8.87911C13.3712 8.8319 13.3642 8.78397 13.3449 8.74048C13.1268 8.24624 12.1916 6.52632 10.6918 4.19321C9.20297 1.87788 8.08051 0.618016 7.70044 0.26118C7.66556 0.228267 7.62245 0.205371 7.57565 0.194904C7.52885 0.184437 7.48009 0.186788 7.43452 0.201707C6.10594 0.658269 4.82406 1.24084 3.60639 1.94145C2.4309 2.62479 1.31802 3.41055 0.28071 4.2896C0.2445 4.32038 0.217353 4.36044 0.202179 4.40548C0.187005 4.45052 0.184377 4.49884 0.194578 4.54526C0.337449 5.21108 1.02036 7.99057 3.1395 11.8406C5.30171 15.7699 6.80014 17.7831 9.97544 20.9474C13.1507 24.1118 15.2275 25.7004 19.1609 27.8626C23.0109 29.9818 25.7917 30.6654 26.4562 30.8069C26.5027 30.817 26.5511 30.8143 26.5962 30.7992C26.6414 30.784 26.6816 30.7569 26.7125 30.7207C27.5914 29.6835 28.377 28.5706 29.06 27.3951C29.7605 26.1773 30.3431 24.8955 30.7997 23.5669C30.8144 23.5217 30.8166 23.4734 30.8063 23.427C30.7959 23.3806 30.7734 23.3378 30.741 23.3031Z'
                  fill='#FFDC60'
                />
              </svg>
            </div>
            <div style={{ cursor: 'pointer' }} onClick={() => window.open('tel:+91-09432672711')} className='label'>
              09432672711
            </div>
          </div>
          <div className='contact'>
            <div className='logo'>
              <svg width='31' height='25' viewBox='0 0 31 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M29.7188 0.46875H1.28125C0.991169 0.46875 0.71297 0.583984 0.507852 0.789102C0.302734 0.99422 0.1875 1.27242 0.1875 1.5625V23.4375C0.1875 23.7276 0.302734 24.0058 0.507852 24.2109C0.71297 24.416 0.991169 24.5312 1.28125 24.5312H29.7188C30.0088 24.5312 30.287 24.416 30.4921 24.2109C30.6973 24.0058 30.8125 23.7276 30.8125 23.4375V1.5625C30.8125 1.27242 30.6973 0.99422 30.4921 0.789102C30.287 0.583984 30.0088 0.46875 29.7188 0.46875ZM16.1713 14.4571C15.9793 14.6064 15.7431 14.6874 15.5 14.6874C15.2569 14.6874 15.0207 14.6064 14.8287 14.4571L4.12158 6.12959L5.46416 4.40283L15.5 12.2081L25.5358 4.40283L26.8784 6.12959L16.1713 14.4571Z'
                  fill='#FFDC60'
                />
              </svg>
            </div>
            <div
              style={{ cursor: 'pointer' }}
              onClick={() => window.open('mailto: info@shivangan.com')}
              className='label'
            >
              info@shivangan.com
            </div>
          </div>
          <div className='contact'>
            <div className='logo'>
              <svg width='23' height='31' viewBox='0 0 23 31' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M11.5 0.1875C5.46182 0.1875 0.5625 4.59736 0.5625 10.0312C0.5625 18.7812 11.5 30.8125 11.5 30.8125C11.5 30.8125 22.4375 18.7812 22.4375 10.0312C22.4375 4.59736 17.5382 0.1875 11.5 0.1875ZM11.5 15.5C10.6347 15.5 9.78885 15.2434 9.06938 14.7627C8.34992 14.2819 7.78916 13.5987 7.45803 12.7992C7.12689 11.9998 7.04025 11.1201 7.20906 10.2715C7.37787 9.42281 7.79455 8.64326 8.40641 8.03141C9.01826 7.41955 9.79781 7.00287 10.6465 6.83406C11.4951 6.66525 12.3748 6.75189 13.1742 7.08303C13.9737 7.41416 14.6569 7.97492 15.1377 8.69438C15.6184 9.41385 15.875 10.2597 15.875 11.125C15.8737 12.2849 15.4124 13.397 14.5922 14.2172C13.772 15.0374 12.6599 15.4987 11.5 15.5Z'
                  fill='#FFDC60'
                />
              </svg>
            </div>
            <div
              style={{ cursor: 'pointer' }}
              onClick={() => window.open('https://goo.gl/maps/UtPLVMNzArQRqZyD6')}
              className='label'
            >
              DC 2/10 Shastri Bagan, Jora Mandir, <br /> Baguiati, VIP Rd, Kolkata, 700059
            </div>
          </div>
        </div>
      </div>
      <div className='footerNewsletter'>
        SUBSCRIBE TO OUR NEWSLETTER
        <form action='' className='newsletter'>
          <input type='text' id='emailInput' placeholder='e.g. johndoe@example.com' />
          <button type='submit'>SUBSCRIBE</button>
        </form>
      </div>
      <center className='footerBottomBar'>©&nbsp;2020-2022&nbsp;Shivangan. All&nbsp;Right&nbsp;Reserved.</center>
    </div>
  );
};

export const FooterContact = () => {
  return (
    <div className='reachoutBanner'>
      Don't call it a hotel, call it a home, away from home!
      <Link className='reachoutBtn' to='/contact'>
        Reach Out
      </Link>
    </div>
  );
};
