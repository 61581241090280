import React, { useState, useEffect } from 'react';

import { AiFillPlayCircle } from 'react-icons/ai';
import { MdFlightLand, MdFlightTakeoff } from 'react-icons/md';
import { ImUsers } from 'react-icons/im';

import './LandingSection.css';
import coverImg from '../../assets/Shivangan_CoverImg.png';

import RoomBooking from '../RoomBooking/RoomBooking';
import VideoModal from '../VideoModal/VideoModal';
import BookingModal from '../BookingModal/BookingModal';

const LandingSection = () => {
  const [isVideo, setIsVideo] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [checkIn, setcheckIn] = useState(new Date().toISOString().split('T')[0]);
  const [checkOut, setCheckOut] = useState(null);
  const [guest, setGuest] = useState(null);

  const handleVideo = () => {
    setIsVideo(true);
  };
  const closeVideo = () => {
    setIsVideo(false);
  };

  const handleModal = () => {
    setIsModalVisible(true);
  };
  const closeModal = () => {
    setIsModalVisible(false);
  };

  const handleCheckIn = (e) => {
    setcheckIn(e.target.value);
  };
  const handleCheckout = (e) => {
    setCheckOut(e.target.value);
  };
  const handleGuest = (e) => {
      
    setGuest(e.target.value);
  };
  useEffect(() => {
    var today = new Date().toISOString().split('T')[0];
    document.getElementById('checkIn').setAttribute('min', today);
      
  }, []);

  return (
    <div className='landingSectionWrapper' id='Home'>
      {isVideo && <VideoModal closeVideo={closeVideo} />}
      {!isVideo && isModalVisible && (
        <BookingModal
          closeModal={closeModal}
          guest={guest != null ? guest : ''}
          checkInDate={checkIn}
          checkOutDate={checkOut != null ? checkOut : ''}
        />
      )}
      <div className='landingLeft landingContainer '>
        <div className='landingDetails'>
          <h2>
            Great rooms, <br /> make great mornings!
          </h2>

          <h4>
            Feel the experience of living in a fresh, quiet and peaceful room whilst being surrounded by the hustle and
            bustle of the city.
          </h4>
          <div className='videoSection' onClick={handleVideo}>
            <AiFillPlayCircle /> <span>Watch Video</span>
          </div>

          {/* <div className='bookingComp'>
            <RoomBooking></RoomBooking>
          </div> */}

          <div className='landingForm'>
            <form>
              <div className='landingFormDivWrapper'>
                <label htmlFor='guests'>
                  <ImUsers />
                </label>
                <div className='landingFormInputWrapper guestInput'>
                  <span>Guests</span>
                  {/* <input type='text' placeholder='How many guests' id='guests' onchange={(e) => handleGuest(e)} /> */}
                  <select name="guests" id='guests' onChange={(e) => handleGuest(e)}>
                    <option disabled selected>How many guests?</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="5+">5+</option>
                  </select>
                </div>
              </div>
              <div className='landingFormDivWrapper'>
                <label htmlFor='checkIn'>
                  <MdFlightLand />
                </label>
                <div className='landingFormInputWrapper'>
                  <span>Check In</span>
                  <input type='date' id='checkIn' name='checkIn' onChange={(e) => handleCheckIn(e)} />
                </div>
              </div>
              <div className='landingFormDivWrapper'>
                <label htmlFor='checkOut'>
                  <MdFlightTakeoff />
                </label>
                <div className='landingFormInputWrapper'>
                  <span>Check Out</span>
                  <input type='date' id='checkOut' name='checkOut' min={checkIn} onChange={(e) => handleCheckout(e)} />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className='landingRight landingContainer'>
        <img src={coverImg} alt='' />
        <div className='landingForm'>
          <button onClick={handleModal}>Book Now</button>
        </div>
      </div>
    </div>
  );
};

export default LandingSection;
