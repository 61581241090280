import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { QueryClient, QueryClientProvider, useQuery } from '@tanstack/react-query'

import LandingPage from './pages/LandingPage';
import ContactPage from './pages/ContactPage/ContactPage';
import RoomsPage from './pages/RoomsPage';
import ReservationPage from './pages/ReservationPage/ReservationPage';


import { Footer } from './components/Footer/Footer';


import MainNav from './components/NavBar/MainNav/MainNav';
import HallRoomBooking from './components/HallRoomBooking/HallRoomBooking';
import { ReviewSection } from './components/ReviewSection/ReviewSection';
import ScrollButton from './components/BackToTop/ScrollButton';

const queryClient = new QueryClient()



function App() {
  return (
    <QueryClientProvider client={queryClient}>
    <div className='App'>
      <ScrollButton/>
      <Router>
        <MainNav />
        <Routes>
          <Route path='' element={<LandingPage />} />
          {/* <Route path='/rooms' element={<RoomsPage />} /> */}
          <Route path='/contact' element={<ContactPage />} />
          <Route path='/reservation' element={<ReservationPage />} />
          <Route path='/hallroombooking' element={<HallRoomBooking />} />
          <Route path='/review' element={<ReviewSection />} />

          <Route path='/reservation/:roomNo' element={<ReservationPage />} />
        </Routes>
        <Footer />
      </Router>
    </div>
    </QueryClientProvider>
  );
}

export default App;
