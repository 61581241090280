import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import wifi from '../../assets/icons/wifi.png';
import washingMachine from '../../assets/icons/washingMachine.png';
import tv from '../../assets/icons/tv.png';
import ac from '../../assets/icons/ac.png';
import shower from '../../assets/icons/shower.png';
import roomService from '../../assets/icons/roomService.png';
import doctor from '../../assets/icons/doctor.png';
import garage from '../../assets/icons/garage.png';

import './AboutSection.css';

const AboutSection = () => {
  return (
    <div className='aboutSection' id='About'>
      <div className='aboutSectionTitle'>About Us</div>
      <div className='description'>
        Shivangan is a 2 star property situated five mins away from airport. It has twenty two rooms, two banquets and a conference.
        Thirty minutes away from sealdah station makes it a prime location for staying. 
        Malls , hospitals are of easy access from Shivangan.

        It also consists of one banquet that can capacitate six hundred people easily and the other one about two hundred making it a good wedding venue. 
        <br />
        <br /> Featuring a complete list of amenities, guests will find their stay at the property a comfortable one.
        Service-minded staff will welcome and guide you to your room. Each guestroom is elegantly furnished and equipped
        with handy amenities. We offer various recreational opportunities for our guests. Friendly staff, great
        facilities and close proximity to all that Kolkata has to offer are three great reasons you should stay with us.
        <br />
        <br />
        WE CUSTOMIZE ACCORDING TO YOUR BUDGET. Let us know your requirements and we will help you to the best of our abilities.
        <br />
        <br />
        If you are looking for a wedding package in kolkata 
        Call us : 9830874068
      </div>

      <div className='amenitiesDesc'>
        <div className='header'>Ammenities we provide</div>
        <div className='amenitiesIconQueue'>
          <div className='amenity'>
            <div className='amenityIcon'>
              <img src={wifi} alt='' />
            </div>
            <div className='amenityLabel'>Internet</div>
          </div>
          <div className='amenity'>
            <div className='amenityIcon'>
              <img src={garage} alt='' />
            </div>
            <div className='amenityLabel'>Airport Pickup</div>
          </div>
          <div className='amenity'>
            <div className='amenityIcon'>
              <img src={doctor} alt='' />
            </div>
            <div className='amenityLabel'>Emergencies</div>
          </div>
          <div className='amenity'>
            <div className='amenityIcon'>
              <img src={roomService} alt='' />
            </div>
            <div className='amenityLabel'>Room Service</div>
          </div>
          <div className='amenity'>
            <div className='amenityIcon'>
              <img src={shower} alt='' />
            </div>
            <div className='amenityLabel'>Hot Water</div>
          </div>
          <div className='amenity'>
            <div className='amenityIcon'>
              <img src={ac} alt='' />
            </div>
            <div className='amenityLabel'>Air Conditioner</div>
          </div>
          <div className='amenity'>
            <div className='amenityIcon'>
              <img src={tv} alt='' />
            </div>
            <div className='amenityLabel'>Television</div>
          </div>
          <div className='amenity'>
            <div className='amenityIcon'>
              <img src={washingMachine} alt='' />
            </div>
            <div className='amenityLabel'>Laundry</div>
          </div>
        </div>
      </div>
      <div className='banquetDesc'>
        <div className='header'>Banquet Hall</div>
        <div className='banquetSubtext'>
          Our Banquet Halls can be the perfect avenue for celebrating your next event. You have the choice of the
          balcony that offers the serenity of the open skies and vast city where you are able to relish your special
          moments with the place. We provide booking for weddings, anniversaries, get togethers, conferences and many
          other event.{' '}
          <Link to='/#Banquet' className='links'>
            Read more.
          </Link>
        </div>
      </div>
      <div className='conferenceDesc'>
        <div className='header'>Conference Room</div>
        <div className='conferenceSubtext'>
          We provide a very spacious and well equipped conference room for hosting your next business meeting. It can
          accomodate upto 20 people.{' '}
          <Link to='/#Conference' className='links'>
            Read more.
          </Link>
        </div>
      </div>
    </div>
  );
};

export default AboutSection;
