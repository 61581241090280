import React from 'react';
import { Link } from 'react-router-dom';

import vertImage from '../../assets/Shivangan VertImg.png';

import './TestimonialsSection.css';

const TestimonialsSection = () => {
  return (
    <div className='testimonial' id='Testimonials'>
      <div className='title'>Testimonials</div>
      <div className='titleQuote'>
        Our goal is to provide people with the most comfortable living experience in Kolkata!
      </div>
      <div className='testWrapper'>
        <div className='imageWrapper'>
          <img src={vertImage} alt='' />
        </div>
        <div className='customerReviewWrapper'>
          <div className='custReview'>
            <div className='mainReview'>
              “Excellent staff and extremely helpful. Good location near the airport. Stayed for 1 night however the
              next day flight was in evening, so we did not want to book another night, so we left our luggage in hotel
              and staff allowed us to come and go as we pleased - at no extra charge. Very polite and we felt
              welcomed...!”
            </div>
            <div className='fromCust'>-Muhammad Teladia</div>
          </div>
          <div className='custReview'>
            <div className='mainReview'>
              “Clean, comfortable, good service, very near to airport, staff behaviour is good. Restaurant food is good.
              Value of money. My father in law and mother in law stayed in this hotel for one day. They are very
              satisfied.”
            </div>
            <div className='fromCust'>-Samir Saha</div>
          </div>
          <div className='custReview'>
            <div className='mainReview'>
              “Awesome: whenever I will come to Kolkata, I will prefer to stay here. Management and all staff are
              showing a very good behaviour. I had come here over 2 years back (11th September 2015) regularly for my
              business. This is my first review. Thanks to all staff and management.”
            </div>
            <div className='fromCust'>-Debasish</div>
          </div>
        </div>
      </div>
      <Link to='/review' className="readMore btn btn-dark" style={{color: '#fff'}}>Read More Reviews</Link>
    </div>
  );
};

export default TestimonialsSection;
