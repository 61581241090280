import React, { useState, useRef, useEffect } from 'react';

import { HashLink } from 'react-router-hash-link';

import { CgMenuRight } from 'react-icons/cg';

import './SideNav.css';

const SideNav = () => {
  const [open, setOpen] = useState(false);
  const [navId, setNavId] = useState(1);
  const sideNavDiv = useRef();
  // const sideNavBtn = useRef();
  useEffect(() => {
    if (open) {
      sideNavDiv.current.classList.add('activeNav');
    } else {
      sideNavDiv.current.classList.remove('activeNav');
    }
  }, [open]);
  const handleNav = (e) => {
    setOpen(!open);    
    setNavId(e)
  };

  

  return (
    <div className='sideNav'>
      <div className='navBtn' onClick={handleNav}>
        <CgMenuRight className='navBtnIcon'/>
        
      </div>
      <div className='sideNavMain' ref={sideNavDiv}>
        <div className='closeNavBtn' onClick={() => handleNav()}>
          &times;
        </div>
        <div className='navList'>
          <HashLink className='navLink' to='/#Home' onClick={() => handleNav(1)}>
            Home
          </HashLink>
          <HashLink className='navLink' to='/#About' onClick={() => handleNav(2)}>
            About
          </HashLink>
          {/* <HashLink className='navLink' to='/rooms' onClick={() => handleNav()}>
            Rooms
          </HashLink> */}
          <HashLink className='navLink' to='/hallroombooking' onClick={() => handleNav(3)}>
            Booking 
          </HashLink>
          <HashLink className='navLink' to='/#Clients' onClick={() => handleNav(4)}>
            Clients
          </HashLink>
          <HashLink className='navLink' to='/#Testimonials' onClick={() => handleNav(5)}>
            Testimonials
          </HashLink>
          <HashLink className='navLink' to='/contact' onClick={() => handleNav(6)}>
            Contacts
          </HashLink>
        </div>
      </div>
    </div>
  );
};

export default SideNav;
