import React from 'react';

import Map from '../../components/Map/Map';
import Form from '../../components/Form/Form';

import './ContactPage.css';

const ContactPage = () => {
  window.scrollTo(0,0)
  return (
    <div
      className='contactPageWrapper'
      style={{
        backgroundColor: 'var(--primary-background)',
      }}
    >
      <div className='mapWrapper' id='Map'>
        <Map />
      </div>
      <div className='contactFormWrapper'>
        <Form />
      </div>
    </div>
  );
};

export default ContactPage;
