import moment from 'moment';
import React,{useState,useRef,useEffect} from 'react';
import {useSearchParams} from "react-router-dom";
import './ReviewSection.css';

export const ReviewSection = () => {
  const [load,setLoad]= useState(false);
  const [error,setError]= useState(false);
  const [rating,setRating]= useState(1);
  const [reviewArray,setReviewArray] = useState(null);
  const [averageRating,setAverageRating] = useState(0);
  const [Onerating,setOneRating]= useState(0);
  const [Tworating,setTwoRating]= useState(0);
  const [Threerating,setThreeRating]= useState(0);
  const [Fourrating,setFourRating]= useState(0);
  const [Fiverating,setFiveRating]= useState(0);
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());


  const reviewModalWrapper= useRef()

  const handleReviewModal = () => {
    reviewModalWrapper.current.classList.add('modalActive')
  }


  // searchParams is a URLSearchParams object.
  // See https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams

useEffect(() => {
getReview()


},[])
useEffect(() => {

  
}, [params]);


const getReview=()=>
{
  setLoad(true)
  
  const baseUrl = process.env.REACT_APP_REVIEW_BASE_URL;  // Please set your Web Apps URL.
  const para = {
    spreadsheetId: process.env.REACT_APP_REVIEW_SPREADSHEET_ID,  // Please set your Google Spreadsheet ID.
    sheetName: process.env.REACT_APP_SHEET_NAME  // Please set the sheet name you want to retrieve the values.
  };
const q = new URLSearchParams(para);
const url = baseUrl + "?" + q;
fetch(url)
  .then(res => res.json())
  .then(res => {
    const values = res.values;
    window.scrollTo(0,0)
      
    setReviewArray(values);
    setLoad(false)
    setError(false)
    var sum1=0;
    var sum2=0;
    var sum3=0;
    var sum4=0;
    var sum5=0;
    
    for(let i=1;i<values.length;i++) {
      if(values[i][3]===1)
      sum1+=1
      else if(values[i][3]===2)
      sum2+=1
      else if(values[i][3]===3)
      sum3+=1
      else if(values[i][3]===4)
      sum4+=1
      else if(values[i][3]===5)
      sum5+=1
        
    }
    setAverageRating((((1*sum1)+(2*sum2)+(3*sum3)+(4*sum4)+(5*sum5))/reviewArray?.length-1).toFixed(1));
      
      
      
      
      
  })
  .catch(e=>{setError(true);setLoad(false)})
  
  ;
  }

useEffect(() => {
  getReview()
  console.log(process.env.REACT_APP_REVIEW_BASE_URL)
}, []);

  return (
    <div className='reviewSection'>
     
      <div className='mainReviewSection'>
        <div className='ratingSection'>
          <div className='overallRating'>
          <div className='title'>OUR REVIEWS</div>
            <div className='ratingVal'>4.5</div>
            <div className='stars'>

              <div className='Stars' style={{ '--rating': 4.5 }} />

      

            </div>
          </div>
          
        </div>

      <div className="reviewMainWrapper">

       <div className='addReviewSection'>
          <div className='reviewText'>Add Your Review</div>
          <div className='reviewModalBtn' onClick={()=>handleReviewModal()}>Click Here</div>
        </div>

        {load?<>Loading....</>:""}
        {error?<>Oops! Something went wrong</>:""}
      {reviewArray?.length>0?<div className='customerReviews'>
          {/* Foreach over the imported google sheets data and place in props for component */}
        
            {reviewArray?.slice(1,reviewArray.length).map((review,idx) => (
          <CustomerReview
          date={moment(review[0]).format('LL')  }
          name={review[1]}
          review={review[2]}
          rating={parseInt(review[3])}
          display={review[4]}
          image='https://st3.depositphotos.com/6672868/13701/v/600/depositphotos_137014128-stock-illustration-user-profile-icon.jpg'
          key={idx}
        />    
            ))} 
        
        </div>
        :
        <></>
        }

        
        </div>

      <div className="reviewModalWrapper" ref={reviewModalWrapper}>
              <ReviewModal/>
      </div>
  
      </div>
    </div>
  );
};

export const ReviewModal = () => {
  const [rating,setRating]= useState(false);
  const ratingWrapper=useRef()

useEffect(() => {
document.querySelectorAll('.ratingStar').forEach(star=>star.style.color="#d9d9d9")
for(let i=0;i<rating;i++)
{
  document.querySelectorAll('.ratingStar')[i].style.color="#fc0"
}
document.getElementById('ratingNum').value=rating;
//    
   
}, [rating]);
  const handleSubmit = (e) => {
    setTimeout(() => {
      alert('Thank you !Your review has been submitted.')
      e.target.parentElement.parentElement.parentElement.classList.remove('modalActive')     
    }, 500);
  
  // e.target.reset()
  }
  return (
    <div   className='card'>
      <h4>Tell us your Exprience</h4>
        <form className='reviewForm' action="https://script.google.com/macros/s/AKfycbx8ie38oOcGVS2etwGgElSQn2Q9OUPKntHK1PIYvUmN5_AooJS9ahxG1-VRjHRKhG39/exec" method='POST' target='frame' >
              <div   className='rating-container' >
                {/* <small>Let us know how much we lived up to your expectations</small> */}
                <input name="Name" className='reviewTextArea' placeholder='Your Name' required/>
                <textarea name="Review" className='reviewTextArea' placeholder='Let us know how much we lived up to your expectations' required />
                <input type="number" name="Rating" defaultValue={rating} style={{display:'none'}} id="ratingNum" required/>
                <div   className='rating' ref={ratingWrapper}>
                  <svg className="ratingStar" width='24' height='24' viewBox='0 0 24 24' fill='currentColor' color='#000' onClick={()=>setRating(1)}>
                    <path d='M0 0h24v24H0z' fill='none'></path>
                    <path d='M0 0h24v24H0z' fill='none'></path>
                    <path d='M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z'></path>
                  </svg>
                  <svg className="ratingStar" width='24' height='24' viewBox='0 0 24 24' fill='currentColor' color='#000' onClick={()=>setRating(2)}>
                    <path d='M0 0h24v24H0z' fill='none'></path>
                    <path d='M0 0h24v24H0z' fill='none'></path>
                    <path d='M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z'></path>
                  </svg>
                  <svg className="ratingStar" width='24' height='24' viewBox='0 0 24 24' fill='currentColor' color='#000' onClick={()=>setRating(3)}>
                    <path d='M0 0h24v24H0z' fill='none'></path>
                    <path d='M0 0h24v24H0z' fill='none'></path>
                    <path d='M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z'></path>
                  </svg>
                  <svg className="ratingStar" width='24' height='24' viewBox='0 0 24 24' fill='currentColor' color='#000' onClick={()=>setRating(4)}>
                    <path d='M0 0h24v24H0z' fill='none'></path>
                    <path d='M0 0h24v24H0z' fill='none'></path>
                    <path d='M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z'></path>
                  </svg>
                  <svg className="ratingStar" width='24' height='24' viewBox='0 0 24 24' fill='currentColor' color='#000' onClick={()=>setRating(5)}>
                    <path d='M0 0h24v24H0z' fill='none'></path>
                    <path d='M0 0h24v24H0z' fill='none'></path>
                    <path d='M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z'></path>
                  </svg>
                </div>
              </div>
              <button   className='btn reviewSubmitBtn'onClick={(e)=>handleSubmit(e)} >Submit</button>
        </form>
      <div className='link' onClick={(e)=>e.target.parentElement.parentElement.classList.remove('modalActive')}>
        Not now
      </div>
      <img
        className='cancel'
        alt=''
        src='https://s2.svgbox.net/materialui.svg?ic=cancel&color=dbd7d7'
        width='32'
        height='32'
        onClick={(e)=>e.target.parentElement.parentElement.classList.remove('modalActive')}
      />

      <iframe name="frame" style={{display:'none'}} frameBorder="0"></iframe>
    </div>
  );
};

export const CustomerReview = (props) => {
  
  return (
    props.display?<div className='review'>
    <div className='custDetails'>
      <div className='customerImg'>
        <img src={props.image} alt='' className='custProfile' />
      </div>
      <div className='customerName'>{props.name}</div>
    </div>
    <div className='mainReview'>
      <div className='custRating'>
        <div className='Stars' style={{ '--rating': props.rating }} />
       
      </div>
      <div className='reviewDate'>{props.date}</div>
      <div className='mainCustReview'>
       {props.review}
      </div>
      
    </div>
  </div>:""
    
  );

    

};
