import { useState,useEffect } from "react";
import {AiFillCaretUp} from 'react-icons/ai'

import './ScrollButton.css'
const ScrollButton = () =>{
  
    const [visible, setVisible] = useState(true)
    const [scrolled, setScrolled] = useState(0)
    
    useEffect(() => {
      toggleVisible()
      
    }, [scrolled]);
    const toggleVisible = () => {
      const scrolled = document.documentElement.scrollTop;
      if (scrolled > 300){
        setVisible(true)
      } 
      else if (scrolled <= 300){
        setVisible(false)
      }
      setScrolled(scrolled)
    };
    
    const scrollToTop = () =>{
      window.scrollTo({
        top: 0, 
        behavior: 'smooth'
      });
    };
    
    window.addEventListener('scroll', toggleVisible);
    
    return (
      <div onClick={scrollToTop}
      className="scrollButton"
      style={{opacity: visible?1:0,pointerEvents:visible?'all':"none"}}
      >
       <AiFillCaretUp/>
      </div>
    );
  }
    
  export default ScrollButton;