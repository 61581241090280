import React, { useEffect, useState } from 'react';
import { MdBalcony } from 'react-icons/md';
import { TbSmoking } from 'react-icons/tb';
import { FaSmokingBan } from 'react-icons/fa';

import { useNavigate } from 'react-router';

import data from '../../data/data.json';
import image1 from '../../assets/images/image 1.png';
import './RoomBookingComponent.css';

const RoomBookingComponent = ({header}) => {
  const [rooms, setRooms] = useState(data);
  const [type, setType] = useState('all');
  const navigate = useNavigate();
  useEffect(() => {
    //    getRoom()
  }, []);
  const ascendingPrice = [...data].sort((a, b) => a.price - b.price);
  const descendingPrice = [...data].sort((a, b) => b.price - a.price);
  const getRoom = async () => {
    const room = await fetch('../../data/data.json');
    const roomdata = await room.json();
      
  };

  const handleRoom = (e) => {
      
    setType(e.target.value);
  };
  const handlePrice = (e) => {
    if (e.target.value == 'ascending') setRooms(ascendingPrice);
    else if (e.target.value == 'descending') setRooms(descendingPrice);
    else setRooms(data);
  };

  return (
    <section className='roomBookingSectionWrapper'>
      <div className={header===false?'hidden':'roomBookingBannerSection'}>Book a room</div>

      <div className='roomFilterSectionWrapper' style={header===false?{marginTop:'-10vh'}:{}}>
        <div className='roomTypeSelector filterDropDown'>
          <span className='filterDesc'>Room Type: </span>
          <select name='roomType' id='roomType' onChange={(e) => handleRoom(e)}>
            <option value='all'>All Rooms</option>
            <option value='Deluxe'> Deluxe Rooms</option>
            <option value='Super Deluxe'>Super Deluxe Rooms</option>
          </select>
        </div>
        <div className='roomTypeSelector'>
          <span className='filterDesc'>Price: </span>
          <select name='price' id='price' onChange={(e) => handlePrice(e)}>
            <option value='ascending'>Low to High</option>
            <option value='descending'>High to Low</option>
          </select>
        </div>
      </div>

      <section className='roomBookingSection'>
        {rooms
          .filter((room) => room.roomType === type || type === 'all')
          .map((e) => (
            <div className={e.booked ? 'roomBookingCard bookedCard' : 'roomBookingCard'} key={e.id}>
              <div className='roomBookingCardImage'>
                <img src={image1} alt='' />
              </div>
              <div className='roomBookingCardDetails'>
                <div className='roomNo'>Room no. {e.roomNo}</div>
                <div className='roomType'>{e.roomType} Room</div>
                <div className='price'>
                  Available at<span>Rs. {e.price}.00</span>/Night
                </div>

                <div className='amenities'>
                  <div className='amenityWrapper'>
                    <MdBalcony /> <span>{e.balcony ? 'Balcony' : 'Non Balcony'}</span>
                  </div>

                  <div className='amenityWrapper'>
                    {e.balcony ? (
                      <>
                        <TbSmoking />
                        <span>Smoking</span>
                      </>
                    ) : (
                      <>
                        <FaSmokingBan />
                        <span>Non smoking</span>
                      </>
                    )}
                  </div>
                </div>
                {e.booked ? (
                  <button className='bookNowBtn ' disabled>
                    Already Booked
                  </button>
                ) : (
                  <button className='bookNowBtn' onClick={() => navigate(`/reservation/${e.roomNo}`)}>
                    Book this room
                  </button>
                )}
              </div>
            </div>
          ))}
      </section>
    </section>
  );
};

export default RoomBookingComponent;
