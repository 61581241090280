import React from 'react';

import image1 from '../../assets/images/image 1.png';
import image3 from '../../assets/images/image 3.png';
import image4 from '../../assets/images/image 5.png';
import image5 from '../../assets/images/image 6.png';
import image6 from '../../assets/images/image 7.png';
import image7 from '../../assets/images/image 8.png';

import './RoomSection.css';

const RoomSection = () => {
  return (
    <div className='roomSection' id='Rooms'>
      <div className='sectionWrapper'>
        <div className='ConfWrapper'>
          <div className='ourRooms heading'>
            <span className='aboutSectionTitle '>Our Rooms</span>
          </div>
          <div className='heading'>
            <span className='header'>Deluxe Rooms</span>
          </div>
          <div className='gallery'>
            <div className='leftWrapper galleryCol'>
              <img src={image1} alt='' className='galleryElem div1' />
              <img src={image3} alt='' className='galleryElem div2' />
            </div>
            <div className='centralWrapper galleryCol'>
              <div className='centralTop'>
                <img src={image6} alt='' className='galleryElem div3' />
                <img src={image4} alt='' className='galleryElem div4' />
              </div>
              <img src={image5} alt='' className='galleryElem div5' />
            </div>
            <div className='rightWrapper galleryCol'>
              <img src={image7} alt='' className='galleryElem div6' />
            </div>
          </div>
        </div>

        <div className='ConfWrapper'>
          <div className='heading'>
            <span className='header'>Super Deluxe Rooms</span>
          </div>
          <div className='gallery'>
            <div className='leftWrapper galleryCol'>
              <img src={image1} alt='' className='galleryElem div1' />
              <img src={image3} alt='' className='galleryElem div2' />
            </div>
            <div className='centralWrapper galleryCol'>
              <div className='centralTop'>
                <img src={image6} alt='' className='galleryElem div3' />
                <img src={image4} alt='' className='galleryElem div4' />
              </div>
              <img src={image5} alt='' className='galleryElem div5' />
            </div>
            <div className='rightWrapper galleryCol'>
              <img src={image7} alt='' className='galleryElem div6' />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RoomSection;
