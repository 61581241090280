import React from 'react';

import LandingSection from '../components/LandingSection/LandingSection';
import AboutSection from '../components/AboutSection/AboutSection';
import RoomSection from '../components/RoomSection/RoomSection';
import ClientSection from '../components/ClientSection/ClientSection';
import TestimonialsSection from '../components/TestimonialsSection/TestimonialsSection';
import HallRoomSection from '../components/HallRoomSection/HallRoomSection'
import { FooterContact } from '../components/Footer/Footer';
import LandingMobile from '../components/LandingSection/LandingMobile';

const LandingPage = () => {
  return (
    <div className='landingPageWrapper'>
      <LandingSection />
      <LandingMobile/>
      <AboutSection />
      <RoomSection />
      <HallRoomSection/>
      <ClientSection />
      <TestimonialsSection />
      <FooterContact />
    </div>
  );
};

export default LandingPage;
