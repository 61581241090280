import React, { useState, useEffect } from 'react';
import { AiFillPlayCircle } from 'react-icons/ai';
import moment from 'moment';
import { MdFlightLand, MdFlightTakeoff } from 'react-icons/md';
import { ImUsers } from 'react-icons/im';

import VideoModal from '../VideoModal/VideoModal';
import BookingModal from '../BookingModal/BookingModal';

import './LandingSection.css';
const LandingMobile = () => {
  const [isVideo, setIsVideo] = useState(false);
  const [weather, setWeather] = useState(null);
  const [temp, setTemp] = useState(null);
  const [now, setNow] = useState(new Date());
  const [date, setDate] = useState('01-01-1990');
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleVideo = () => {
    setIsVideo(true);
  };
  const closeVideo = () => {
    setIsVideo(false);
  };

  useEffect(() => {
    getWeather();
    setDate(moment().format(' MMMM Do ').toString());
  }, []);
  const updateNow = () => {
    setNow(new Date());
  };

  useEffect(() => {
    setTimeout(updateNow, 1000);
  });

  const formatClockNum = (clock_num) => {
    return clock_num.toString().padStart(2, '0');
  };

  const getWeather = () => {
    fetch(
      `https://api.openweathermap.org/data/2.5/weather?lat=22.6483428&lon=88.4694441&units=metric&appid=aa8fafad6088c959ffa5045db2e9d1a8`
    )
      .then((res) => res.json())
      .then((data) => {
        setTemp(data.main.temp);
        setWeather(data.weather[0].main);
      })
      .catch((err) => console.error(err))
  };

  const handleModal = () => {
    setIsModalVisible(true);
  };
  const closeModal = () => {
    setIsModalVisible(false);
  };

  return (
    <section className='landingSectionMobile'>
      {isVideo && <VideoModal closeVideo={closeVideo} />}
      {!isVideo && isModalVisible && <BookingModal closeModal={closeModal} />}
      <div className='weatherWrapper'>
        <div className='weather'>
          <div className='weatherIcon'></div>
          <div className='weatherDetails'>
            <span id='temperature'>{temp}&deg;C</span> <span id='weatherDesc'>{weather}</span>
          </div>
        </div>
        <div className='greetings'>Hello</div>
        <div className='timing'>
          <span id='time'>
            {formatClockNum(now.getHours())}:{formatClockNum(now.getMinutes())}
          </span>
          <span id='day'>{date}</span>
        </div>
      </div>
      <div className='landingDetails'>
        <h2>
          Great rooms, <br /> make great mornings!
        </h2>

        <h4>
          Feel the experience of living in a fresh, quiet and peaceful room whilst being surrounded by the hustle and
          bustle of the city.
        </h4>
        <div className='videoSection' onClick={handleVideo}>
          <AiFillPlayCircle /> <span>Watch Video</span>
        </div>
      </div>

      {/* <div className='landingForm'>
            <form>
              <div className='landingFormDivWrapper'>
                <label htmlFor='guests'>
                  <ImUsers />
                </label>
                <div className='landingFormInputWrapper guestInput'>
                  <span>Guests</span>
                  <input type='text' placeholder='How many guests' id='guests' />
                </div>
              </div>
              <div className='landingFormDivWrapper'>
                <label htmlFor='checkIn'>
                  <MdFlightLand />
                </label>
                <div className='landingFormInputWrapper'>
                  <span>Check In</span>
                  <input type='date' id='checkIn'/>
                </div>
              </div>
              <div className='landingFormDivWrapper'>
                <label htmlFor='checkOut'>
                  <MdFlightTakeoff />
                </label>
                <div className='landingFormInputWrapper'>
                  <span>Check Out</span>
                  <input type='date'  id='checkOut'/>
                </div>
              </div>
            
            </form>
            <button>Book Now</button>
          </div> */}
      <div className='bookingBtn' onClick={handleModal}>
        Book A Room
      </div>
    </section>
  );
};

export default LandingMobile;
