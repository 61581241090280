import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { AiFillCaretDown } from 'react-icons/ai';
import data from '../../data/data.json';
import './ReservationForm.css';
const ReservationForm = () => {
  const roomNo = useParams().roomNo;
  const [room, setRoom] = useState(roomNo);
  const [currentData, setCurrentData] = useState(null);
  const [currentRoomType, setCurrentRoomType] = useState(null);
  const [checkIn, setCheckIn] = useState(new Date().toISOString().split('T')[0]);
  const [checkOut, setCheckOut] = useState(null);

  useEffect(() => {
      
    setCurrentData(data.filter((x) => x.roomNo == room)[0]);
  }, [room]);

  useEffect(() => {
      
    setCurrentRoomType(currentData?.roomType);
  }, [currentData]);
  useEffect(() => {
    var today = new Date().toISOString().split('T')[0];
    document.getElementById('date-arrival').setAttribute('min', today);
    document.getElementById('date-arrival').setAttribute('value', today);
      
  }, []);
  const handleRoomNoChange = (e) => {
    setRoom(e.target.value);
  };

  const handleCheckIn = (e) => {
    setCheckIn(e.target.value);
  };
  const handleCheckout = (e) => {
    setCheckOut(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    var formData = new FormData(e.target);
    var encode = `Room%20Booking%20for%20Hotel%20Shivangan%0A%0A---CUSTOMER%20DETAILS---%0A%0AName%3A%20${formData.get(
      'fname'
    )}%20${formData.get('lname')}%20%0AEmail%3A%20${formData.get('email').split('@')[0]}%40${
      formData.get('email').split('@')[1]
    }%0ANumber%3A%20${formData.get(
      'number'
    )}%0A%0A----------------------%0A%0A---GUESTS---%0A%0AAdults%3A%20${formData.get(
      'adults'
    )}%0AChildren%3A%20${formData.get(
      'children'
    )}%0A%0A----------------------%0A%0A---ROOM%20DETAILS---%0A%0ARoom%20Number%20%3A%20${room}%0A%0ARoom%20Type%20%3A%20${currentRoomType}%0ACheck%20In%20Date%20%3A%20${checkIn}%0ACheck%20Out%20Date%20%3A%20${formData.get(
      'dateDeparture'
    )}%0A%0A---------------------%0A%0A----------------------%0A%0A---MODE%20OF%20PAYMENT---%0A%0AUPI%20ID%3A%20${formData.get(
      'upiId'
    )}%0A%0A---ADDITIONAL%20INFORMATION---%0AMessage%20From%20User%3A%20N%2FA%0A%0A---------------------------`;

    window.open(`https://wa.me/09432672711/?text=${encode}`);
  };

  return (
    <section className='reservationSection'>
      <section className='reservationFormWrapper'>
        <div   className='col-md-12'>
          <h2   className='mt0'>Reservation Form</h2>
          <form   className='probootstrap-form' onSubmit={(e) => handleSubmit(e)}>
            <div   className='row'>
              <div   className='col-md-6'>
                <div   className='form-group'>
                  <label for='fname'>First Name</label>
                  <input type='text'   className='form-control' id='fname' name='fname' placeholder='Enter your first name' required />
                </div>
              </div>
              <div   className='col-md-6'>
                <div   className='form-group'>
                  <label for='lname'>Last Name</label>
                  <input type='text'   className='form-control' id='lname' name='lname' placeholder='Enter your last name'  required />
                </div>
              </div>
            </div>
            <div   className='form-group'>
              <label for='email'>Email</label>
              <div   className='form-field'>
                <i   className='icon icon-mail'></i>
                <input type='email'   className='form-control' id='email' name='email' placeholder='Enter your email' required />
              </div>
            </div>

            <div   className='form-group'>
              <label for='number'>Phone Number</label>
              <div   className='form-field'>
                <i   className='icon icon-mail'></i>
                <input type='text'   className='form-control' id='number' name='number' placeholder='Enter your phone number' required />
              </div>
            </div>

            <div   className='form-group'>
              <label for='room'>Room No</label>
              <div   className='form-field'>
                <i>
                  <AiFillCaretDown />
                </i>
                <select
                  name='roomNo'
                  id='roomNo'
                    className='form-control'
                  onChange={(e) => handleRoomNoChange(e)}
                  defaultValue={room}
                >
                  <option value='' disabled>
                    Select a Room No
                  </option>
                  {data.map((option, index) => (
                    <option value={option.roomNo} disabled={option.booked ? true : false} key={index}>
                      {option.roomNo}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div   className='form-group'>
              <label for='room'>Room Type</label>
              <div   className='form-field'>
                <i   className='icon'>
                  <AiFillCaretDown />
                </i>
                <select name='roomType' id='room'   className='form-control' defaultValue={currentRoomType}>
                  <option value='' disabled>
                    Select a Room
                  </option>
                  <option
                    value='Deluxe'
                    selected={currentRoomType == 'Deluxe' ? true : false}
                    disabled={currentData?.roomType ? true : false}
                  >
                    Deluxe Room
                  </option>
                  <option
                    value='Super Deluxe'
                    selected={currentRoomType == 'Super Deluxe' ? true : false}
                    disabled={currentData?.roomType ? true : false}
                  >
                    Super Deluxe Room
                  </option>
                </select>
              </div>
            </div>
            <div   className='row'>
              <div   className='col-md-6'>
                <div   className='form-group'>
                  <label for='date-arrival'>Arrival</label>
                  <div   className='form-field'>
                    <i   className='icon icon-calendar2'></i>
                    <input
                      type='date'
                        className='form-control'
                      id='date-arrival'
                      name='dateArrival'
                      onChange={(e) => handleCheckIn(e)}
                      required
                    />
                  </div>
                </div>
              </div>
              <div   className='col-md-6'>
                <div   className='form-group'>
                  <label for='date-departure'>Departure</label>
                  <div   className='form-field'>
                    <i   className='icon icon-calendar2'></i>
                    <input
                      type='date'
                        className='form-control'
                      id='date-departure'
                      name='dateDeparture'
                      min={checkIn}
                      onChange={(e) => handleCheckout(e)}
                      required
                    />
                  </div>
                </div>
              </div>
            </div>
            <div   className='row mb30'>
              <div   className='col-md-6'>
                <div   className='form-group'>
                  <label for='adults'>Adults</label>
                  <div   className='form-field'>
                    <i   className='icon'>
                      <AiFillCaretDown />
                    </i>
                    <select name='adults' id='adults'   className='form-control'>
                      <option value='' disabled>
                        Number of Adults
                      </option>
                      <option value='1'>1</option>
                      <option value='2'>2</option>
                      <option value='3'>3</option>
                      <option value='4'>4+</option>
                    </select>
                  </div>
                </div>
              </div>
              <div   className='col-md-6'>
                <div   className='form-group'>
                  <label for='children'>Children</label>
                  <div   className='form-field'>
                    <i   className='icon'>
                      <AiFillCaretDown />
                    </i>
                    <select name='children' id='children'   className='form-control'>
                      <option value='' disabled>
                        Number of Children
                      </option>
                      <option value='0'>0</option>
                      <option value='1'>1</option>
                      <option value='2'>2</option>
                      <option value='3'>3</option>
                      <option value='4'>4+</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div   className='form-group'>
              <label for='upiId'>Upi Id</label>
              <div   className='form-field'>
                <i   className='icon icon-mail'></i>
                <input
                  type='text'
                    className='form-control'
                  id='upiId'
                  name='upiId'
                  placeholder='e.g. user@bankname'
                  required
                />
              </div>
            </div>

            <button type='submit'   className='submitBtn' id='submit' name='submit'>
              Reserve
            </button>
          </form>
        </div>
      </section>
    </section>
  );
};

export default ReservationForm;
