import React from "react";
import { useState, useEffect } from "react";

import { AiFillCaretDown } from 'react-icons/ai';

import BanqImage1 from "../../assets/images/banquet/image 17.png";
import BanqImage2 from "../../assets/images/banquet/image 19.png";
import BanqImage3 from "../../assets/images/banquet/image 21.png";
import BanqImage4 from "../../assets/images/banquet/image 22.png";
import BanqImage5 from "../../assets/images/banquet/image 23.png";
import BanqImage6 from "../../assets/images/banquet/image 24.png";

import ConfImage1 from "../../assets/images/conference/image 25.png";
import ConfImage2 from "../../assets/images/conference/image 26.png";
import ConfImage3 from "../../assets/images/conference/image 27.png";
import ConfImage4 from "../../assets/images/conference/image 28.png";
import ConfImage5 from "../../assets/images/conference/image 29.png";
import ConfImage6 from "../../assets/images/conference/image 30.png";
import RoomBookingComponent from "../RoomBookingComponent/RoomBookingComponent";

import "./HallRoomBooking.css";

const BanquetHall = () => {
  return (
    <div className="gallery">
      <div className="leftWrapper galleryCol">
        <img src={BanqImage1} alt="" className="galleryElem div1" />
        <img src={BanqImage2} alt="" className="galleryElem div2" />
      </div>
      <div className="centralWrapper galleryCol">
        <div className="centralTop">
          <img src={BanqImage6} alt="" className="galleryElem div3" />
          <img src={BanqImage5} alt="" className="galleryElem div4" />
        </div>
        <img src={BanqImage3} alt="" className="galleryElem div5" />
      </div>
      <div className="rightWrapper galleryCol">
        <img src={BanqImage4} alt="" className="galleryElem div6" />
      </div>
    </div>
  );
};
const ConferenceRoom = () => {
  return (
    
  // <img src={ConfImage6} alt="" className="galleryElem" />    
  <div className="gallery">
      <div className="leftWrapper galleryCol">
        <img src={ConfImage6} alt="" className="galleryElem div1" />
        {/* <img src={ConfImage2} alt="" className="galleryElem div2" /> */}
      </div>
  </div>
  );
};

const ConferenceRoomForm = () => {
  return (
    <section className="reservationSection hallRoomBookingReservationSection">
      <section className="reservationFormWrapper">
        <div   className="col-md-12">
          <h2   className="mt0">Reservation Form</h2>
          <form   className="probootstrap-form">
            <div   className="row">
              <div   className="col-md-6">
                <div   className="form-group">
                  <label for="fname">First Name</label>
                  <input
                    type="text"
                      className="form-control"
                    id="fname"
                    name="fname"
                    placeholder="Enter your first name"
                    required
                  />
                </div>
              </div>
              <div   className="col-md-6">
                <div   className="form-group">
                  <label for="lname">Last Name</label>
                  <input
                    type="text"
                      className="form-control"
                    id="lname"
                    name="lname"
                    placeholder="Enter your last name"
                    required
                  />
                </div>
              </div>
            </div>
            <div   className="form-group">
              <label for="email">Email</label>
              <div   className="form-field">
                <i   className="icon icon-mail"></i>
                <input
                  type="email"
                    className="form-control"
                  id="email"
                  name="email"
                  placeholder="Enter your email"
                  required
                />
              </div>
            </div>

            <div   className="form-group">
              <label for="number">Phone Number</label>
              <div   className="form-field">
                <i   className="icon icon-mail"></i>
                <input
                  type="text"
                    className="form-control"
                  id="number"
                  name="number"
                  placeholder="Enter your phone number"
                  required
                />
              </div>
            </div>

            <div className="row">
            <div   className="form-group col-md-6">
              <label for="number">Company</label>
              <div   className="form-field">
                <i   className="icon icon-mail"></i>
                <input
                  type="text"
                    className="form-control"
                  id="company"
                  name="company"
                  placeholder="Enter company name"
                  required
                />
              </div>
            </div>

            <div   className="form-group col-md-6">
              <label for="number">Designation</label>
              <div   className="form-field">
                <i   className="icon icon-mail"></i>
                <input
                  type="text"
                    className="form-control"
                  id="designation"
                  name="designation"
                  placeholder="Enter your designation"
                  required
                />
              </div>
            </div>
            </div>
         
         <div className="row">

            <div   className="form-group col-md-6">
              <label for="numberPurpose">Purpose</label>
              <div   className="form-field">
                <i   className="icon icon-mail"></i>
                <input
                  type="text"
                    className="form-control"
                  id="Purpose"
                  name="Purpose"
                  placeholder="State your purpose"
                  required
                  />
              </div>
            </div>
            <div   className="form-group col-md-6">
                  <label for="adults">Number of Attendees</label>
                  <div   className="form-field">
                    <i   className="icon">
                      <AiFillCaretDown />
                    </i>
                    <select name="adults" id="adults"   className="form-control">
                      <option value="" disabled>
                        Number of Attendees
                      </option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4+</option>
                    </select>
                  </div>
                </div>
          </div>

            <div   className="row">
              <div   className="col-md-6">
                <div   className="form-group">
                  <label for="date-arrival">Date</label>
                  <div   className="form-field">
                    <i   className="icon icon-calendar2"></i>
                    <input
                      type="text"
                        className="form-control"
                      id="date-arrival"
                      name="dateArrival"
                      placeholder="Enter event date"
                      onFocus={(e)=>e.target.type="date"}
                      required
                    />
                  </div>
                </div>
              </div>
              <div   className="col-md-6">
                <div   className="form-group">
                  <label for="date-departure">Time</label>
                  <div   className="form-field">
                    <i   className="icon icon-calendar2"></i>
                    <input
                      type="text"
                        className="form-control"
                      id="date-departure"
                      name="dateDeparture"
                      placeholder="Enter event time"
                      onFocus={(e)=>e.target.type="time"}                      
                      required
                    />
                  </div>
                </div>
              </div>
            </div>
           
              
                <div   className="form-group">
                  <label for="date-arrival">Duration</label>
                  <div   className="form-field">
                  <i   className="icon">
                      <AiFillCaretDown />
                    </i>
                    <select name="duration" id="duration"   className="form-control">
                      <option value="" disabled>
                        Duration
                      </option>
                      <option value="1">1 hour</option>
                      <option value="2">2 hours</option>
                      <option value="3">3 hours</option>
                      <option value="4">4+ hours</option>
                    </select>
                  </div>
                </div>
              
              
          <div className="row">
          <div   className="form-group col-md-6">
                  <label for="Equipments">Equipments</label>
                  <div   className="form-field">
                  <i   className="icon">
                      <AiFillCaretDown />
                    </i>
                    <i   className="icon icon-calendar2"></i>
                    <select className="form-control" name="refreshments" id="refreshments" required>
                      <option value="Projector">Projector</option>
                      <option value="WhiteBoard">WhiteBoard</option>
                    </select>
                  </div>
                </div>
          <div   className="form-group col-md-6">
                  <label for="refreshments">Refreshments</label>
                  <div   className="form-field">
                  <i   className="icon">
                      <AiFillCaretDown />
                    </i>
                    <i   className="icon icon-calendar2"></i>
                    <select className="form-control" name="refreshments" id="refreshments" required>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  </div>
                </div>
          </div>

            <button type="submit"   className="submitBtn" id="submit" name="submit">
            Book an appointment
            </button>
          </form>
        </div>
      </section>
    </section>
  );
};

const BanquetHallForm = () => {
  return (
    <section className="reservationSection hallRoomBookingReservationSection">
      <section className="reservationFormWrapper" style={{width:'min(700px,90vw)'}}>
        <div   className="col-md-12">
          <h2   className="mt0">Consultation Form</h2>
          <form   className="probootstrap-form" >
            <div   className="row">
              <div   className="col-md-6">
                <div   className="form-group">
                  <label for="fname">First Name</label>
                  <input
                    type="text"
                      className="form-control"
                    id="fname"
                    name="fname"
                    placeholder="Enter your first name"
                    required
                  />
                </div>
              </div>
              <div   className="col-md-6">
                <div   className="form-group">
                  <label for="lname">Last Name</label>
                  <input
                    type="text"
                      className="form-control"
                    id="lname"
                    name="lname"
                    placeholder="Enter your last name"
                    required
                  />
                </div>
              </div>
            </div>
            <div   className="form-group">
              <label for="email">Email</label>
              <div   className="form-field">
                <i   className="icon icon-mail"></i>
                <input
                  type="email"
                    className="form-control"
                  id="email"
                  name="email"
                  placeholder="Enter your email address"
                  required
                />
              </div>
            </div>

            <div   className="form-group">
              <label for="number">Phone Number</label>
              <div   className="form-field">
                <i   className="icon icon-mail"></i>
                <input
                  type="text"
                    className="form-control"
                  id="number"
                  name="number"
                  placeholder="Enter your phone number"
                  required
                />
              </div>
            </div>

            <div className="row">
                <div   className="form-group col-md-6">
                  <label for="date-arrival">Event Date</label>
                  <div   className="form-field">
                    <i   className="icon icon-calendar2"></i>
                    <input
                      type="text"
                        className="form-control"
                      id="date-arrival"
                      name="dateArrival"
                      // onChange={(e) =>   
                      placeholder="Enter event date"
                      onFocus={(e)=>e.target.type="date"}
                      required
                    />
                  </div>
               
                </div>

                <div   className="form-group col-md-6">
                  <label for="date-arrival">Event Type</label>
                  <div   className="form-field">
                  <i   className="icon">
                      <AiFillCaretDown />
                    </i>
                    <select name="type" id="type"   className="form-control">
                      <option  disabled selected>
                       Choose Event Type
                      </option>
                      <option value="marriage">Marriage</option>
                      <option value="birthday">Birthday</option>
                      <option value="anniversary">Anniversary</option>
                      <option value="other">Other</option>
                    </select>
                  </div>
               
                </div>

            </div>
            <div   className="row mb30">
              <div   className="col-md-6">
                <div   className="form-group">
                  <label for="adults">Guests</label>
                  <div   className="form-field">
                    <i   className="icon">
                      <AiFillCaretDown />
                    </i>
                    <select name="adults" id="adults"   className="form-control">
                      <option  disabled selected>
                       Choose Number of Guests
                      </option>
                      <option value="1">10-50</option>
                      <option value="2">50-100</option>
                      <option value="3">100-150</option>
                      <option value="4">150+</option>
                    </select>
                  </div>
                </div>
              </div>
              <div   className="col-md-6">
                <div   className="form-group">
                  <label for="children">Food Type</label>
                  <div   className="form-field">
                    <i   className="icon">
                      <AiFillCaretDown />
                    </i>
                    <select name="children" id="children"   className="form-control">
                      <option  disabled selected>
                        Type of Food
                      </option>
                      <option value="veg">Veg</option>
                      <option value="non-veg">Non-Veg</option>
                      <option value="none">None</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <button type="submit"   className="submitBtn" id="submit" name="submit">
              Book an appointment
            </button>
          </form>
        </div>
      </section>
    </section>
  );
};
const HallRoomBooking = () => {
  const [roomState, setRoomState] = useState(0);

  useEffect(() => {
    document
      .querySelectorAll(".hallType")
      ?.forEach((hallType) => hallType?.classList.remove("activeHall"));
    // document.querySelector(".hallBookingBannerSection")?.style.background = "url('gg')"

    document
      .querySelectorAll(".hallType")
      ?.[roomState].classList.add("activeHall");
  }, [roomState]);
  return (
    <section className="roomBookingSectionWrapper">
      <div className="hallBookingBannerSection">
        Book{" "}
        {roomState === 0
          ? "Rooms"
          : roomState === 1
          ? "Banquet Hall"
          : "Conference Room"}
      </div>

      <div className="hallFilterSectionWrapper">
        <div className="hallTypeSelector">
          <div
            className="hallType Room"
            onClick={() => {
              setRoomState(0);
            }}
          >
            Rooms
          </div>
          <div
            className="hallType Banq"
            onClick={() => {
              setRoomState(1);
            }}
          >
            Banquet Hall
          </div>
          <div
            className="hallType Conf"
            onClick={() => {
              setRoomState(2);
            }}
          >
            Conference Room
          </div>
        </div>
      </div>

      {roomState === 0 ? (
        <RoomBookingComponent header={false} />
      ) : roomState === 1 ? (
        <>
        <BanquetHall />
        <BanquetHallForm/>
        </>
      ) : (
        <section className="conferenceHallBookingSection">
          <div className="col-md-5 " style={{display: 'flex', alignItems: 'center',justifyContent: 'center'}}>
        <ConferenceRoom />
          </div>
          <div className="col-md-5">
        <ConferenceRoomForm/>
          </div>
        </section>
        
      )}
 
    </section>
  );
};

export default HallRoomBooking;
