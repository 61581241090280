import React from 'react'

import FeedbackSection from '../../components/FeedbackSection/FeedbackSection'
import ReservationForm from '../../components/ReservationForm/ReservationForm'

import './ReservationPage.css'

const ReservationPage = () => {
  window.scrollTo(0,0)
  return (
    <section className='reservationSectionWrapper'>
    <div className="reservationBannerSection">
            Reserve a Room
        </div>
    <div className='reservationPage'>
        <ReservationForm/>
    <FeedbackSection/>
    </div>
    </section>
  )
}

export default ReservationPage