import React from 'react';

import logo1 from '../../assets/logos/Logo (1).jpg';
import logo2 from '../../assets/logos/Logo (2).jpg';
import logo3 from '../../assets/logos/Logo (3).jpg';
import logo4 from '../../assets/logos/Logo (4).jpg';
import logo5 from '../../assets/logos/Logo (5).jpg';
import logo6 from '../../assets/logos/Logo (6).jpg';
import logo7 from '../../assets/logos/Logo (7).jpg';
import logo8 from '../../assets/logos/Logo (8).jpg';
import logo9 from '../../assets/logos/Logo (9).jpg';
import logo10 from '../../assets/logos/Logo (10).jpg';
import logo11 from '../../assets/logos/Logo (11).jpg';
import logo12 from '../../assets/logos/Logo (1).png';

import './ClientSection.css';

const ClientSection = () => {
  return (
    <div className='clientSection' id='Clients'>
      <div className='aboutSectionTitle'>Our Clients</div>
      <div className='clientsWrapper'>
        <div className='separator'></div>
        <div className='clients'>
          <div className='client'>
            <img src={logo1} alt='' />
          </div>
          <div className='client'>
            <img src={logo12} alt='' />
          </div>
          <div className='client'>
            <img src={logo2} alt='' />
          </div>
          <div className='client'>
            <img src={logo3} alt='' />
          </div>
          <div className='client'>
            <img src={logo4} alt='' />
          </div>
          <div className='client'>
            <img src={logo5} alt='' />
          </div>
          <div className='client'>
            <img src={logo6} alt='' />
          </div>
          <div className='client'>
            <img src={logo7} alt='' />
          </div>
          <div className='client'>
            <img src={logo11} alt='' />
          </div>
          <div className='client'>
            <img src={logo9} alt='' />
          </div>
          <div className='client'>
            <img src={logo10} alt='' />
          </div>
          <div className='client'>
            <img src={logo8} alt='' />
          </div>
        </div>
        <div className='separator'></div>
      </div>
    </div>
  );
};

export default ClientSection;
