import React from 'react';

import BanqImage1 from '../../assets/images/banquet/image 17.png';
import BanqImage2 from '../../assets/images/banquet/image 19.png';
import BanqImage3 from '../../assets/images/banquet/image 21.png';
import BanqImage4 from '../../assets/images/banquet/image 22.png';
import BanqImage5 from '../../assets/images/banquet/image 23.png';
import BanqImage6 from '../../assets/images/banquet/image 24.png';

import ConfImage1 from '../../assets/images/conference/image 25.png';
import ConfImage2 from '../../assets/images/conference/image 26.png';
import ConfImage3 from '../../assets/images/conference/image 27.png';
import ConfImage4 from '../../assets/images/conference/image 28.png';
import ConfImage5 from '../../assets/images/conference/image 29.png';
import ConfImage6 from '../../assets/images/conference/image 30.png';

import './HallRoomSection.css';

const HallRoomSection = () => {
  return (
    <div className='hallRoomSection'>
      <div className='sectionWrapper'>
        <div className='BanqWrapper' id='Banquet'>
          <div className='header'>
            <span className='aboutSectionTitle'>Our Banquet Hall</span>
          </div>
          <div className='gallery'>
            <div className='leftWrapper galleryCol'>
              <img src={BanqImage1} alt='' className='galleryElem div1' />
              <img src={BanqImage2} alt='' className='galleryElem div2' />
            </div>
            <div className='centralWrapper galleryCol'>
              <div className='centralTop'>
                <img src={BanqImage6} alt='' className='galleryElem div3' />
                <img src={BanqImage5} alt='' className='galleryElem div4' />
              </div>
              <img src={BanqImage3} alt='' className='galleryElem div5' />
            </div>
            <div className='rightWrapper galleryCol'>
              <img src={BanqImage4} alt='' className='galleryElem div6' />
            </div>
          </div>
        </div>
        <div className='ConfWrapper' id='Conference'>
          <div className='header'>
            <span className='aboutSectionTitle'>Our Conference Room</span>
          </div>
          <div className="gallery">
      <div className="leftWrapper galleryCol">
        <img src={ConfImage6} alt="" className="galleryElem" />
        {/* <img src={ConfImage2} alt="" className="galleryElem div2" /> */}
      </div>
  </div>
        </div>
      </div>
    </div>
  );
};

export default HallRoomSection;
