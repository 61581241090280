import React from 'react';

import './VideoModal.css';

const VideoModal = (props) => {
  return (
    <div className='videoModal'>
      <div className='videoWrapper'>
        <div className='closeVideoBtn' onClick={props.closeVideo}>
          &times;
        </div>
        <iframe
          src='https://www.youtube.com/embed/6SBNejEtpXI?controls=0'
          title='Hotel Shivanagan'
          frameBorder='0'
          allow='autoplay; clipboard-write; encrypted-media; picture-in-picture'
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
};

export default VideoModal;
