import React from 'react';

import { Link } from 'react-router-dom';

import logo from '../../../assets/Shivangan Logo Transparent 1.png';
import SideNav from '../SideNav/SideNav';

import './MainNav.css';


const MainNav = () => {
  return (
    <div className='navSection'>
      <Link className='titleLogoSection' to="/" >
        <img src={logo} alt='' />
      </Link>
      <h3 id='shivanganTitle'>SHIVANGAN</h3>
      <SideNav />
    </div>
  );
};

export default MainNav;
