import React from 'react';
import './Map.css';

const Map = () => {
  return (
    <div className='mapWrapper' style={{ zIndex: 98 }}>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1493.6350203678633!2d88.4287641675968!3d22.613658400088582!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f89e367ca0081f%3A0x44673c8ca22c8ab9!2sShivangan%20Hotel!5e0!3m2!1sen!2sin!4v1660038099546!5m2!1sen!2sin"
        loading='lazy'
        referrerpolicy='no-referrer-when-downgrade'
      ></iframe>
    </div>
  );

  
};

export default Map;
