import React from 'react';

import { MdLocalPhone, MdEmail, MdLocationOn } from 'react-icons/md';

import './Form.css';

const Form = () => {
  const slideBtn = () => {};
  return (
    <div className='contactWrapper'>
      <div className='contactTitle'>Get in Touch</div>
      <div className='mainWrapper'>
        <div className='contactSection'>
          <div className='contact'>
            <div className='contactLogo'>
              <MdLocalPhone />
            </div>

            <div className='contactInfo'>09432672711</div>
          </div>
          <div className='contact'>
            <div className='contactLogo'>
              <MdEmail />
            </div>

            <div className='contactInfo'>info@shivangan.com</div>
          </div>
          <div className='contact'>
            <div className='contactLogo'>
              <MdLocationOn />
            </div>

            <div className='contactInfo'>
              DC 2/10 Shastri Bagan, Jora Mandir,
              <br /> Baguiati, VIP Rd, Kolkata, 700059
            </div>
          </div>
        </div>
        <div className='formSection'>
          <form action='' className='mainForm'>
            <div className='formTitle'>Contact Form</div>
            <fieldset className='formField'>
              <legend>Name</legend>
              <input type='text' className='formEntry' name='Name' placeholder='Enter Your Name' />
            </fieldset>

            <fieldset className='formField'>
              <legend>Email</legend>
              <input type='text' className='formEntry' name='Email' placeholder='Enter Your Email' />
            </fieldset>

            <fieldset className='formField'>
              <legend>Phone No.</legend>
              <input type='text' className='formEntry' name='PhoneNum' placeholder='Enter Your Phone Number' />
            </fieldset>

            <fieldset className='formField'>
              <legend>Message</legend>
              <textarea className='formEntry formTxtArea' name='Message' placeholder='Enter Your Message' />
            </fieldset>
            <button type='submit' className='sendMsgBtn' onClick={(e) => e.preventDefault()}>
              Send Message
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Form;
