import React, { useEffect } from 'react';
import './BookingModal.css';

const BookingModal = (props) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    var formData = new FormData(e.target);
    var encode = `Room%20Booking%20for%20Hotel%20Shivangan%0A%0A---CUSTOMER%20DETAILS---%0A%0AName%3A%20${formData.get(
      'Name'
    )}%20%0AEmail%3A%20${formData.get('email').split('@')[0]}%40${
      formData.get('email').split('@')[1]
    }%0ANumber%3A%20${formData.get(
      'number'
    )}%0A%0A----------------------%0A%0A---GUESTS---%0A%0AAdults%3A%20${formData.get(
      'adults'
    )}%0AChildren%3A%20${formData.get(
      'children'
    )}%0A%0A----------------------%0A%0A---ROOM%20DETAILS---%0A%0A%0A%0ARoom%20Type%20%3A%20${formData.get(
      'roomType'
    )}%0ACheck%20In%20Date%20%3A%20${formData.get('arrivalDate')}%0ACheck%20Out%20Date%20%3A%20${formData.get(
      'departureDate'
    )}%0A%0A---------------------%0A%0A----------------------%0A%0A---MODE%20OF%20PAYMENT---%0A%0AUPI%20ID%3A%20${formData.get(
      'upiId'
    )}%0A%0A---ADDITIONAL%20INFORMATION---%0AMessage%20From%20User%3A%20${formData.get(
      'message'
    )}%0A%0A---------------------------`;

    window.open(`https://wa.me/09432672711/?text=${encode}`);
  };
useEffect(() => {
  
    
}, []);
  return (
    <div className='bookingModal'>
      <div className='modalWrapper'>
        <div className='modalTop'>
          <span className='modalTitle'>Book A Room</span>
          <span className='crossBtn' onClick={props.closeModal}>
            &times;
          </span>
        </div>
        <div className='modalMain'>
          <form className='modalForm' onSubmit={(e) => handleSubmit(e)}>
            <div className='modalField'>
              <label htmlFor='' className='modalLabel'>
                Name
              </label>
              <input type='text' className='modalInput' placeholder='Enter Your Name' name='Name' required />
            </div>
            <div className='modalField'>
              <label htmlFor='' className='modalLabel'>
                Phone Number
              </label>
              <input type='text' className='modalInput' placeholder='Enter Your Phone Number' name='number' required />
            </div>
            <div className='modalField'>
              <label htmlFor='' className='modalLabel'>
                Email
              </label>
              <input type='text' className='modalInput' placeholder='Enter Your Email' name='email' required />
            </div>
            <div className='modalField'>
              <label htmlFor='' className='modalLabel'>
                Guests (Adults)
              </label>
              <select name='adults' id='adults' className='modalInput' defaultValue={props?.guest} required>
                <option value='1'>1 Adult</option>
                <option value='2'>2 Adults</option>
                <option value='3'>3 Adults</option>
                <option value='4'>4 Adults</option>
                <option value='5'>5 Adults</option>
                <option value='5+'>5+ Adults</option>
              </select>
            </div>
            <div className='modalField'>
              <label htmlFor='' className='modalLabel'>
                Guests (Child)
              </label>
              <select name='children' id='' className='modalInput' required>
                <option value='0' default>
                  None
                </option>
                <option value='1'>1 Child</option>
                <option value='2'>2 Children</option>
                <option value='3'>3 Children</option>
                <option value='3+'>3+ Children</option>
              </select>
            </div>
            <div className='modalField'>
              <label htmlFor='' className='modalLabel'>
                Check in
              </label>
              <input type='date' name='arrivalDate' className='modalInput' defaultValue={props.checkInDate} required />
            </div>
            <div className='modalField'>
              <label htmlFor='' className='modalLabel'>
                Check out
              </label>
              <input
                type='date'
                name='departureDate'
                className='modalInput'
                defaultValue={props?.checkOutDate}
                required
              />
            </div>
            <div className='modalField'>
              <label htmlFor='' className='modalLabel'>
                Room Type
              </label>
              <select name='roomType' id='' className='modalInput' required>
                <option value='deluxe'>Deluxe</option>
                <option value='supDeluxe'>Super Deluxe</option>
                <option value='balcony'>Balcony</option>
              </select>
            </div>
            <div className='modalField'>
              <label htmlFor='' className='modalLabel'>
                Upi Id
              </label>
              <input type='text' className='modalInput' placeholder='e.g user@bankName' name='upiId' required />
            </div>
            <div className='modalField'>
              <label htmlFor='' className='modalLabel'>
                Any Message (Optional)
              </label>
              <textarea
                name='message'
                id=''
                className='modalInput'
                placeholder='Enter Your Message (Optional)'
              ></textarea>
            </div>
            <button type='submit' className='bookRoomBtn'>
              Book Room
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default BookingModal;
