import React from 'react'

import {Link} from 'react-router-dom'

import './FeedbackSection.css'

const FeedbackSection = () => {
  return (
    <div className='feedbackSection'>
        <div className="feedbackTitle">Feedback</div>
        <div className="subText">We try to be of best service to you, but sometimes we also make mistakes. If you have any valuable suggestions for us, please write to us. </div>
        <Link to='/review' className='sendMsgBtn'>Give Review</Link>
    </div>
  )
}

export default FeedbackSection